import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { AssetBorrow, AssetSupply, Borrow, Supply } from "../components";
import CoinModal from "../components/CoinModal";
import imageURL from "../hooks/imageUrl";
import imageUrl from '../hooks/imageUrl'
import { connectWallet } from "../utils/connectWallet";

function Home(props) {
  const {
    active,
    activate,
} = useWeb3React();
  return (
    <main>
      <section className="section-first">
        <Container fluid className="custom-container">
          <div className="center-div">
          <button className="home-dropdown-btn">
            <img src={imageURL('ethereum.svg')} />
            <span>Ehereum Market</span>
            <i class="fa-solid fa-chevron-down"></i>
          </button>
          <div className="net-pay-section">
            <div className="inner">
                <div className="icon-div">
                  <i class="fa-solid fa-wallet"></i>
                </div>
                <div className="text">
                  <span>Net worth</span>
                  <span>____</span>
                </div>
            </div>
            <div className="inner">
                <div className="icon-div">
                <i class="fa-solid fa-square-poll-vertical"></i>
                </div>
                <div className="text">
                  <span>Net worth</span>
                  <span>____</span>
                </div>
            </div>
          </div>
          </div>
        </Container>
      </section>
      <section className="second-section">
        {
          !active ?
          <div className="box-main box-top-minus">
              <div className="text-center wallet-not-connected">
                <img src={imageUrl('logo1color.png')} className=""/>
                <h4>Please, connect your wallet</h4>
                <p className="normal-text">Please connect your wallet to see your supplies, borrowings, and open positions.</p>
                <button className="custom-btn primary-btn" onClick={() => {
                        connectWallet(activate, props.setErrorMessage);
                    }}>Connect Wallet</button>
              </div>
          </div>
          :
          <Row>
            <Col lg={6}>
            <div className="box-main box-top-minus">
              <Supply/>
            </div>
            </Col>
            <Col lg={6}>
            <div className="box-main box-top-minus m-top-none">
              <Borrow/>
            </div>
            </Col>
            <Col lg={6}>
            <div className="box-main mt-4">
              <AssetSupply/>
            </div>
            </Col>
            <Col lg={6}>
            <div className="box-main mt-4">
              <AssetBorrow/>
            </div>
            </Col>
          </Row>
         
        }
        
      </section>
    </main>
  );
}

export default Home;
