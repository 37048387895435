import { useState } from "react";
import "./App.css";
import { useEagerConnect, useInactiveListener } from "./hooks/useEagerConnect";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "./components";
import { Home, Markets } from "./screens";

function App() {
  const [errorMessage, setErrorMessage] = useState();
  useEagerConnect(setErrorMessage);
  useInactiveListener();
  const [nav,setNav] = useState(false)

  return (
    <div className="App">
      <BrowserRouter>
        <Header setErrorMessage={setErrorMessage} nav={nav} setNav={setNav}/>
        <Routes>
          <Route path="/" element={<Home setErrorMessage={setErrorMessage}/>} />
          <Route path="/markets" element={<Markets setErrorMessage={setErrorMessage}/>} />
        </Routes>
        {
          nav &&
          <div className="sidebar-overlay" onClick={()=>setNav(false)}>

          </div>
        }
      </BrowserRouter>
    </div>
  );
}
export default App;
