import { useWeb3React } from '@web3-react/core';
import { connectWallet } from "../utils/connectWallet";

import { Link, NavLink } from 'react-router-dom'
import '../assets/css/header.css'
import imageURL from '../hooks/imageUrl'
import { useState } from 'react';
function Header(props) {
    const {
        active,
        activate,
        account,
    } = useWeb3React();
    const truncAddress = (str, n = 6) => {
        if (str) {
            return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
        }
        return '';
    };
    return <>
        <header className='custom-header'>
            <div className='header-body'>
                <div className='logo-section'>
                    <img src={imageURL('logo.png')} className="logo" />
                    <i class="fa-solid fa-bars head-icon open-icon" onClick={()=>props.setNav(true)}></i>
                </div>
                <div className={'nav-main '+ (props.nav ? 'open-sidebar' : 'close-sidebar')}>
                <div className='mobile-logo-section'>
                    <img src={imageURL('logo.png')} className="logo" />
                    <i class="fa-solid fa-xmark head-icon" onClick={()=>props.setNav(false)}></i>
                </div>
                <ul className='nav'>
                    <li className='nav-link'>
                        <NavLink to={'/'} className="link">Dashboard</NavLink>
                    </li>
                    <li className='nav-link'>
                        <NavLink to={'/markets'} className="link">Markets</NavLink>
                    </li>

                </ul>
                <div className='connect-section d-d-none text-center'>
                {
                    active?<button className='custom-btn primary-btn' >{truncAddress(account,4)}</button>:
                    <button className='custom-btn primary-btn' onClick={() => {
                        connectWallet(activate, props.setErrorMessage);
                    }}>Connect Wallet</button>
                }
                
                </div>
                </div>
            </div>
            <div className='connect-section m-d-none'>
                {
                    active?<button className='custom-btn primary-btn' >{truncAddress(account,4)}</button>:
                    <button className='custom-btn primary-btn' onClick={() => {
                        connectWallet(activate, props.setErrorMessage);
                    }}>Connect Wallet</button>
                }
                
            </div>
        </header>
    </>
}

export default Header