import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { MarketTable } from "../components";
import CoinModal from "../components/CoinModal";
import imageURL from "../hooks/imageUrl";
import imageUrl from '../hooks/imageUrl'
import { connectWallet } from "../utils/connectWallet";

function Markets(props) {
    const {
        active,
        activate,
    } = useWeb3React();
    return (
        <main>
            <section className="section-first">
                <Container fluid className="custom-container">
                    <div className="center-div">
                        <button className="home-dropdown-btn">
                            <img src={imageURL('ethereum.svg')} />
                            <span>Ehereum Market</span>
                            <i class="fa-solid fa-chevron-down"></i>
                        </button>
                        <div className="net-pay-section">
                            <div className="inner">
                                <div className="icon-div">
                                    <i class="fa-solid fa-chart-pie"></i>
                                </div>
                                <div className="text">
                                    <span>Total market size</span>
                                    <span>$0.00</span>
                                </div>
                            </div>
                            <div className="inner">
                                <div className="icon-div">
                                    <i class="fa-solid fa-arrow-right-arrow-left rotate-icon"></i>
                                </div>
                                <div className="text">
                                    <span>Total available</span>
                                    <span>$0.00</span>
                                </div>
                            </div>
                            <div className="inner">
                                <div className="icon-div">
                                    <i class="fa-solid fa-arrow-right-arrow-left rotate-icon"></i>
                                </div>
                                <div className="text">
                                    <span>Total borrows</span>
                                    <span>$0.00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="second-section">
                <div className="box-main box-top-minus">
                    <div class="table-responsive">
                    <MarketTable />
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Markets;
