import { Table } from "react-bootstrap";
import imageURL from "../hooks/imageUrl";

export default function MarketTable() {
  const coins = [
    { name: "Ethereum", short: "ETH", img: imageURL("ethereum.svg")},
    { name: "Tether", short: "USDT", img: imageURL("mmtokenlogos/1.jpg")},
    { name: "USD Coin", short: "USDC", img: imageURL("mmtokenlogos/2.jpg")},
    { name: "Binance USD", short: "BUSD", img: imageURL("mmtokenlogos/3.jpg")},
    { name: "Polygon", short: "MATIC", img: imageURL("mmtokenlogos/4.jpg")},
    { name: "Shiba Inu", short: "Shiba", img: imageURL("mmtokenlogos/5.jpg")},
    { name: "Uniswap", short: "UNI", img: imageURL("mmtokenlogos/6.jpg")},
    { name: "Dai", short: "Dai", img: imageURL("mmtokenlogos/7.jpg")},
    { name: "Wrapped Bitcoin", short: "WBTC", img: imageURL("mmtokenlogos/8.jpg")},
    { name: "Chainlink", short: "Link", img: imageURL("mmtokenlogos/9.jpg")},
    { name: "Aave", short: "Aave", img: imageURL("mmtokenlogos/10.jpg")},
    { name: "Maker", short: "MKR", img: imageURL("mmtokenlogos/11.jpg")},
    { name: "CUSDC", short: "CUSDC", img: imageURL("mmtokenlogos/12.jpg")},
    { name: "Fantom", short: "FTM", img: imageURL("mmtokenlogos/13.jpg")},
    { name: "Synthetix Network", short: "SNX", img: imageURL("mmtokenlogos/14.jpg")},
    { name: "Wrapped Ethereum", short: "WETH", img: imageURL("mmtokenlogos/15.jpg")},
  ];

  return (
    <>
      <h4 className="sm-title">Ethereum assets</h4>
      <Table className="table-custom mt-4">
        <thead>
          <tr>
            <th>Asset</th>
            <th>Total supplied</th>
            <th>Supply APY</th>
            <th>Total borrowed</th>
            <th>Borrow APY, variable</th>
            <th>Borrow APY, stable</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {coins.map((coin) => {
            return (
              <tr>
                <td>
                  <div className="icon-flex">
                    <img src={coin.img} alt="" />
                    <div>
                      <span>
                        <b>{coin.name}</b>
                      </span>
                      <br />
                      <span>{coin.short}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <b>0.00</b>
                  <br />
                  <span>$0.00</span>
                </td>
                <td>
                  <b>0.00</b>
                </td>
                <td>
                  <b>0.00</b>
                  <br />
                  <span>$0.00</span>
                </td>
                <td>
                  <b>0%</b>
                </td>
                <td>
                  <b>---</b>
                </td>
                <td>
                  <button className="custom-btn secondary-btn">Details</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
