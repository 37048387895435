export default function Borrow()
{
    return <>
        <h4 className="sm-title">Your borrows</h4>
        <div className="empty-table">
            <p className="normal-text">
                Nothing supplied yet
            </p>
        </div>
    </>
}