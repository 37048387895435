import imageURL from "../hooks/imageUrl";

export default function AssetSupply()
{
    return <>
        <h4 className="sm-title">Assets to supply</h4>
        <div className="info-message">
            <i class="fa-solid fa-circle-info "></i>
            <span>Your Ethereum wallet is empty. Purchase or transfer assets</span>
        </div>
        <div className="custom-table">
            <div className="tr">
                <div className="th">
                    Assets
                </div>
                <div className="th">
                Wallet balance

                </div>
                <div className="th text-center">
                APY

                </div>
                <div className="th">
                Can be collateral

                </div>
                <div className="th" style={{width:'170px'}}>

                </div>
            </div>
            <div className="tr">
                <div className="td">
                    <div className="icon-flex">
                        <img src={imageURL('bnb.png')} alt="" />
                        <span>BUSD</span>
                    </div>
                </div>
                <div className="td text-center">
                    <span className="gray-text">0</span>
                </div>
                <div className="td text-center">
                    <span className="text-dark">0.28</span>
                </div>
                <div className="td text-center">
                    —
                </div>
                <div className="td" style={{width:'170px'}}>
                    <div className="button-flex">
                        <button className="custom-btn secondary-btn" disabled>Supply</button>
                        <button className="custom-btn secondary-btn">Details</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}